import React, { useState, useEffect, useRef } from 'react';
import './gear.css';
import mobileImage from './assets/1_00089.png';

// Import the sequence of images
function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context('./assets/GearImages', false, /\.(png|jpe?g|svg)$/)
);

function Gear() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isGearSticky, setIsGearSticky] = useState(false);
  const [scale, setScale] = useState(0.5); // Initial scale
  const [gearOpacity, setGearOpacity] = useState(0); // Initial opacity
  const [gearWattStyle, setGearWattStyle] = useState({ left: '20%', opacity: 1 });
  const [gearTorqueStyle, setGearTorqueStyle] = useState({ right: '20%', opacity: 1 });
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  const gearContainerRef = useRef(null);
  const gearHeadingRef = useRef(null);
  const gear2Ref = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!isMobileView) {
      const handleScroll = () => {
        const gearContainer = gearContainerRef.current;
        const gear2 = gear2Ref.current;
        const gearHeading = gearHeadingRef.current;
        const gear2Top = gear2.getBoundingClientRect().top;
        const gearHeadingTop = gearHeading.getBoundingClientRect().top;

        const totalImages = images.length;
        const gearContainerTop = gearContainer.offsetTop;
        const gearContainerHeight = gearContainer.offsetHeight;
        const windowHeight = window.innerHeight;
        const scrollPosition = window.scrollY;

        const scrollableHeight = gearContainerHeight - windowHeight;
        const startScroll = gearContainerTop;
        const endScroll = gearContainerTop + scrollableHeight;

        if (gearHeadingTop <= windowHeight * 0.75) {
          const progress = Math.min(Math.max((windowHeight * 0.75 - gearHeadingTop) / (windowHeight * 0.75), 0), 1);
          const newScale = 0.5 + progress * 0.5;
          const newOpacity = progress;

          setScale(newScale);
          setGearOpacity(newOpacity);
        } else {
          setGearOpacity(0);
          setScale(0.5);
        }

        if (scrollPosition >= startScroll && scrollPosition <= endScroll) {
          const progress = (scrollPosition - startScroll) / (endScroll - startScroll);
          const imageIndex = Math.floor(progress * Math.min(totalImages, 90));

          setCurrentImageIndex(Math.min(Math.max(imageIndex, 0), 89));
          setIsGearSticky(gear2Top <= 100);
        }
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [isMobileView]);

  const currentImage = isMobileView
    ? mobileImage
    : images[currentImageIndex]?.default || images[currentImageIndex];

  return (
    <div className="gear-container" ref={gearContainerRef}>
      <div className="content">
        <h2
          className="content__title content_ready"
          style={{ color: 'white' }}
          {...(!isMobileView ? { "data-splitting": true, "data-effect10": true } : {})}
        >
          <span className="font-medium font-1">THE</span>
          <span className="font-7">ULTIMATE RIDING</span>
          <span className="font-4">EXPERIENCE</span>
        </h2>
      </div>
      <div className="gear-heading" ref={gearHeadingRef}>
        <div
          className="gear-power"
          style={{
            transform: `scale(${isMobileView ? 1.2 : scale})`,
            opacity: gearOpacity,
            transition: 'transform 0.5s ease-out, opacity 0.5s ease-out',
          }}
        ></div>
        <div className="gear2" ref={gear2Ref}>
          {currentImage ? (
            <img src={currentImage} className="gear-pic" alt={`gear ${currentImageIndex}`} />
          ) : (
            <p>Loading Image...</p>
          )}
        </div>
      </div>
      <div className="gear-setting">
        <p className="gear-watt" style={gearWattStyle}>
          POWER <br />
          <span className="font-watt">
            90 <span className="kilowatt-space">KW</span>
          </span>
        </p>
        <p className="gear-torque" style={gearTorqueStyle}>
          TORQUE <br />
          <span className="font-watt">
            150 <span className="kilowatt-space">NM</span>
          </span>
        </p>
      </div>
      <div className="gear-text">
        <p className="gear-para">
          DISCOVER TRUE FREEDOM WITH MERCELLENIE<br /> SUPERBIKES OFFERING UNPARALLELED RANGE.
        </p>
      </div>
    </div>
  );
}

export default Gear;
